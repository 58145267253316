/* istanbul ignore file */

import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as StoreProvider } from "react-redux";

import { waitFor } from "@jti/fe-common";
import { store } from "@jti/store";
import { LocaleContext } from "@jti/ui";

import AppRunner from "../../components/AppRunner";
import { getGlobalStore } from "../../utils/globalStore";

import CartMini from "./CartMini";

import "@jti/ui/styles/base.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// We check for Cart store in global scope and use it
// if we haven't found global store - we will use local one
waitFor(
  () => Boolean(getGlobalStore()),
  (resolvedByTest) => {
    const container = document.querySelector("[data-cart-mini-container]");
    const root = createRoot(container!);

    if (container) {
      root.render(
        <StoreProvider store={resolvedByTest ? getGlobalStore() : store}>
          <LocaleContext.Provider value={"en-US"}>
            <QueryClientProvider client={queryClient}>
              <CartMini />
              <AppRunner />
            </QueryClientProvider>
          </LocaleContext.Provider>
        </StoreProvider>
      );
    }
  }
);
