import { __ } from "@jti/fe-common";
import { DEPRECATED_Button } from "@jti/ui";

type CartMiniCheckoutButton = {
  isVisible: boolean;
  qtyPending: boolean;
  buyLimitation: boolean;
  onCheckoutClick: () => void;
};

const CartMiniCheckoutButton = ({
  isVisible,
  buyLimitation,
  onCheckoutClick,
  qtyPending,
}: CartMiniCheckoutButton): JSX.Element | null => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={"mini-cart__checkout-button-container"}>
      <DEPRECATED_Button
        type="button"
        variant="submit"
        disabled={buyLimitation || qtyPending}
        label={__("cart.cart_mini.button.checkout")}
        className={"mini-cart__checkout-button"}
        onClick={onCheckoutClick}
        dataTestId="miniCartCheckoutButton"
      />
    </div>
  );
};

export default CartMiniCheckoutButton;
