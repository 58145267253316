import { FC, memo } from "react";

import { __ } from "@jti/fe-common";
import { DEPRECATED_Button } from "@jti/ui";

type Props = {
  onClick(): void;
};

export const RemoveBtn: FC<Props> = memo(({ onClick }) => {
  return (
    <DEPRECATED_Button
      variant="linky"
      type="button"
      onClick={onClick}
      label={__("cart.cart_item.button.remove")}
      dataTestId="cartRemoveButton"
    />
  );
});
