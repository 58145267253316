import { FC, memo } from "react";

import { sanitize } from "dompurify";

import { __ } from "@jti/fe-common";
import { Product } from "@jti/magento";
import { Card, DEPRECATED_Button, Dialog, Image, Price, Txt } from "@jti/ui";

import styles from "./RemoveItemDialog.module.scss";

type RemoveItemDialogProps = {
  item: Product;
  isOpenDialog: boolean;
  onRemove(): void;
  onCancel(): void;
};

const RemoveItemDialog: FC<RemoveItemDialogProps> = ({ isOpenDialog, onCancel, onRemove, item }) => {
  return (
    <Dialog className={styles.dialog} isOpened={isOpenDialog} onClose={onCancel}>
      <div className={styles.title}>{__("cart.items.remove_dialog.title")}</div>
      <div className={styles.cardItem} data-testid={"remove-item-dialog"}>
        <Card variant="variant1" key={item.name}>
          <div className={styles.container}>
            <div className={styles.image}>
              <Image url={item.imageUrl} />
            </div>
            <div>
              <Txt className={styles.name}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item.name),
                  }}
                />
              </Txt>
              <Txt className={styles.description}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(item.description),
                  }}
                />
              </Txt>
              <Price price={item.price} className={styles.price} />
            </div>
          </div>
        </Card>
      </div>
      <div className={styles.btnGroup}>
        <DEPRECATED_Button
          dataTestId="remove-item-cancel-button"
          className={styles.button}
          type="button"
          variant="default"
          label={__("com.address.dialog.cancel_button_label")}
          onClick={onCancel}
        />
        <DEPRECATED_Button
          dataTestId="remove-item-submit-button"
          className={styles.button}
          type="button"
          variant="submit"
          label={__("com.address.dialog.save_button_label")}
          onClick={onRemove}
        />
      </div>
    </Dialog>
  );
};

export default memo(RemoveItemDialog);
