// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompactItem-module-quantityAndPrice-q3NU9{display:grid;align-items:center;grid-template-columns:auto;margin-top:20px;grid-gap:20px;gap:20px;grid-template-areas:\"price\" \"quantity\"}@media(min-width: 960px){.CompactItem-module-quantityAndPrice-q3NU9{grid-template-areas:\"quantity price\" \"subscriptionPrice subscriptionPrice\"}}.CompactItem-module-warning-cbGXJ{margin-top:20px}.CompactItem-module-remove-aFbVR{display:flex;justify-content:flex-end;margin-top:10px}.CompactItem-module-remove-aFbVR button{font-size:14px !important}.CompactItem-module-quantity-LkovI{display:grid;grid-area:quantity;grid-auto-flow:column;justify-content:start}@media(min-width: 960px){.CompactItem-module-quantity-LkovI{grid-template-columns:auto 1fr auto}}.CompactItem-module-price-dcEe4{grid-area:price;text-align:start;color:#5c615e;font-size:16px;font-weight:500;letter-spacing:2.5px;line-height:23px}@media(min-width: 960px){.CompactItem-module-price-dcEe4{text-align:end}.CompactItem-module-price-dcEe4.CompactItem-module-subscriptionPrice-Bxi34{text-align:end;margin-block:-10px 10px;margin-inline:0;grid-area:subscriptionPrice;white-space:normal}}.CompactItem-module-rows-NLcWA a{z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quantityAndPrice": "CompactItem-module-quantityAndPrice-q3NU9",
	"warning": "CompactItem-module-warning-cbGXJ",
	"remove": "CompactItem-module-remove-aFbVR",
	"quantity": "CompactItem-module-quantity-LkovI",
	"price": "CompactItem-module-price-dcEe4",
	"subscriptionPrice": "CompactItem-module-subscriptionPrice-Bxi34",
	"rows": "CompactItem-module-rows-NLcWA"
};
export default ___CSS_LOADER_EXPORT___;
