import { FC, memo } from "react";

import { sanitize } from "dompurify";

import { Txt } from "@jti/ui";

import styles from "./ProductDescription.module.scss";

type Props = {
  description: string;
};

const ProductDescription: FC<Props> = ({ description }) => {
  return (
    <Txt variant="txt-1">
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
    </Txt>
  );
};

export default memo(ProductDescription);
