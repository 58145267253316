import { Order, OrderItem, Product } from "@jti/magento";
import { Cart, FreeTrialCartType } from "@jti/store";
import { CommerceEventBaseData, CommerceEventItem } from "@jti/tracker";

const NO_VALUE = "N/A";

const buildCommerceItemsArray = (arrayWithCartItems?: (Product | OrderItem)[]): CommerceEventItem[] => {
  if (!arrayWithCartItems?.length) {
    return [];
  }
  return arrayWithCartItems.reduce<CommerceEventItem[]>((acc, item, index) => {
    if (!item) {
      return acc;
    }
    const discount = item.price_range?.maximum_price?.discount?.percent_off;

    const data: CommerceEventItem = {
      index: String(index),
      currency: item.price.currency,
      item_category: item.categories ? item.categories[0]?.name : NO_VALUE,
      item_category2: item.categories ? item.categories[1]?.name : NO_VALUE,
      item_color: item.color_label?.toLowerCase() ?? NO_VALUE,
      item_flavor: String(item.flavor ?? NO_VALUE),
      item_name: item.name,
      item_sku: item.sku,
      item_list_name: item.categories ? `${item.categories[0]?.name} > ${item.categories[1]?.name}` : NO_VALUE,
      item_list_id: item.categories ? `${item.categories[0]?.name} > ${item.categories[1]?.name}` : NO_VALUE,
      price: String(item.price.value),
      quantity: String(item.quantity),
      discount: `${discount ?? 0}%`,
      item_id: ("item_id" in item ? item.item_id : "id" in item && item.id) || NO_VALUE,
      item_brand: "ploom",
      item_coupon: NO_VALUE,
      item_coupon_type: NO_VALUE,
      item_strength: NO_VALUE,
      item_rewards: NO_VALUE,
    };
    acc.push(data);
    return acc;
  }, []);
};
export const buildCommerceBaseData = (trackingData: Cart | FreeTrialCartType | Order): CommerceEventBaseData => {
  return {
    currency:
      "currency" in trackingData ? trackingData.currency.code : trackingData.prices?.grandTotal.currency || "N/A",
    value: String(
      trackingData.prices &&
        ("total" in trackingData.prices ? trackingData.prices.total : trackingData.prices.grandTotal.value)
    ),
    ecommerce: {
      items: buildCommerceItemsArray(trackingData.items),
    },
  };
};
